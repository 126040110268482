<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>

      <div class="row justify-content-md-center p-3">
        <div class="col-12 col-md-12">
          <!-- Assunto -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                  <div class="col-12 ">
                    De: {{ formData.sender.email}}
                  </div>
                  <div class="col-12 ">
                    Para: {{ formData.to.email}}
                  </div>
                  <div class="col-12 ">
                    Assunto: {{ formData.subject }}
                  </div>
                  <div class="col-12 ">
                    Último evento:
                  </div>

                  <div class="col-12 ">
                    Histórico de eventos:

                    <div  v-for="(event, i) in formData.events" v-bind:key="i" class="row">
                      <div class="col-8">
                        {{ event.event}}
                      </div>
                      <div class="col-4">
                        {{ $util.formatDateTime(event.createdAt) }}
                      </div>
                    </div>

                  </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Submit -->
        <div class="col-12">
          <button type="button" class="btn btn btn-outline-light pull-right" @click="onSave">Salvar</button>
          <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">Cancelar</button>
        </div>
      </div>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import CustomerService from '@/services/CustomerService'
import CurrencyService from '@/services/CurrencyService'
import CupomService from '@/services/CupomService'
import MailService from '../../services/MailService'

export default {
  name: 'ProcessList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.cupom') + ' - %s'
    }
  },
  data () {
    return {
      // Configuration to mount table of selected values
      formData: {
        subject: null,
        contentHtml: null,
        sender: {
          name: null,
          email: null
        },
        to: {
          name: null,
          email: null
        },
        events: []
      },
      formErrors: {},
      isLoading: true,
      fullPage: true,
      // List of options to select on select input
      currencyList: [],
      submitted: false
    }
  },
  components: {
    Loading
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isLoading = true
    // Load basilares data

    let promises = [
    ]

    // If is edit populate values
    let id = this.$route.params.id

    promises.push(MailService.getEventHistory(id))

    Promise.all(promises).then((values) => {
      // Edit Info
      if (id) {
        let info = values[0].data.data
        _this.formData = info
      }
      _this.isLoading = false
    }).catch(() => {
      _this.isLoading = false
    })
  },
  methods: {
    setCustomer (value) {
      this.formData.idCliente = value
    },
    validCustomer () {
      if (this.formData.idCliente) {
        return true
      }

      return false
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'CupomIndex' })
    },
    taxesTransform (value) {
      let objectValue = {
        id: value.id,
        nome: value.nome,
        valor: 0.00,
        idMoeda: 110
      }

      return objectValue
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    currencyFilter (option, label, search) {
      let temp = search.toLowerCase()

      return (option.nome.toLowerCase().indexOf(temp) > -1 || option.sigla.toLowerCase().indexOf(temp) > -1)
    },
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    /**
     * Triggered when the currency search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    currencyFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      CurrencyService.getCurrencies(filters).then(res => {
        this.hasData = res.data.hasData
        this.currencyList = res.data.data
        loading(false)
      })
    },
    cupomFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      CupomService.getTiposCupom(filters).then(res => {
        this.cupomList = res.data.data
        loading(false)
      })
    },
    customerFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      CustomerService.getCustomers(filters).then(res => {
        this.hasData = res.data.hasData
        this.customerList = res.data.data
        loading(false)
      })
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validateAll('cupomForm').then(valid => {
        if (valid) {
          _this.isLoading = true

          let data = Object.assign({}, _this.formData)

          data.idmoeda = data.idmoeda.id
          data.idCliente = data.idCliente.id
          data.idTipoCupom = data.idTipoCupom.id

          let id = this.$route.params.id
          if (id) {
            CupomService.put(id, data).then(response => {
              _this.$router.push({ name: 'CupomIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          } else {
            CupomService.post(data).then(response => {
              _this.$router.push({ name: 'CupomIndex' })
            }).finally(() => {
              _this.isLoading = false
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;

  }
</style>
